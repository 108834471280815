import React from "react";
import ScoreRow from "../../ScoreRow";

class AccordionItem extends React.Component {
  state = {
    opened: false
  };

  render() {
    const {
      props: { title, values },
      state: { opened }
    } = this;

    let accordionItem = [];
    Object.entries(values).forEach(([key, val]) => {
      const { value, max } = val;
      accordionItem.push({ title: key, value: value, max: max });
    });
    return (
      <div
        {...{
          className: `accordion-item, ${opened && "accordion-item--opened"}`,
          onClick: () => {
            this.setState({ opened: !opened });
          }
        }}
      >
        <div {...{ className: "accordion-item__line" }}>
          <h3 {...{ className: "accordion-item__title" }}>{title}</h3>
          <span {...{ className: "accordion-item__icon" }} />
        </div>
        <div {...{ className: "accordion-item__inner" }}>
          <div {...{ className: "accordion-item__content" }}>
            <div {...{ className: "accordion-item__paragraph" }}>
              {accordionItem.map((data, key) => {
                const { title, value, max } = data;
                return (
                  <ScoreRow
                    title={title}
                    value={value}
                    min={1}
                    max={max}
                    key={title}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AccordionItem;
