import React from "react";
import Slider from "rc-slider";
import Tooltip from "rc-tooltip";
import "./ScoreRow.scss";
import "./bootstrap.css";

const Handle = Slider.Handle;

const handle = props => {
  const { value, dragging, index, ...restProps } = props;
  return (
    <Tooltip
      prefixCls="rc-slider-tooltip"
      overlay={value}
      visible={dragging}
      placement="top"
      key={index}
    >
      <Handle value={value} {...restProps} />
    </Tooltip>
  );
};

class ScoreRow extends React.Component {
  cache = [];

  memoize = max => {
    if (this.cache[max] !== undefined) {
      return this.cache[max];
    }
    let marks = {};
    for (let i = 1; i <= max; i++) {
      marks[i] = i;
    }

    this.cache[max] = marks;
    return this.memoize(max);
  };

  render() {
    const { title, value, max } = this.props;
    let marks = this.memoize(max);
    let label = value;
    if (value === 7) {
      label = "N7";
    }
    marks[value] = {
      style: {
        fontSize: "1.6em",
        color: "azure",
        textShadow: "rgb(0, 11, 11) 1px 1px 2px",
        margin: "2px"
      },
      label: label
    };

    return (
      <>
        <h3 className="slider-header">{title}</h3>
        <Slider
          disabled={true}
          min={1}
          max={max}
          defaultValue={value}
          step={null}
          marks={marks}
          handle={handle}
        />
      </>
    );
  }
}

export default ScoreRow;
