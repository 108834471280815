import React from "react";
import "./App.scss";
import Options from "./Options";
import { RingLoader } from "react-spinners";
import Accordion from "./Accordion";

const axios = require("axios");

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loading: true };
    this.fetchData = this.fetchData.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    this.setState({ loading: true });
    axios
      .get("https://api-mestarter.jeffharris.us/")
      .then(res => {
        const data = res.data;
        this.setState({ data });
        this.setState({ loading: false });
      })
      .catch(error => {
        this.setState({ error: error.message });
        console.debug(error.message);
      });
    return false;
  }

  render() {
    const header = (
      <header className="App-header">Mass Effect Shepard Generator</header>
    );
    if (this.state.error) {
      return (
        <div className="App">
          <div className="Body-wrapper">
            {header}
            <h2>An error has occurred.</h2>
            <span className="error">
              Warning: Data corruption detected.
              <br />
              Automatic reconstruction failed.
              <br />
              Unable to find Shepard.
              <br />
              Please try again later.
            </span>
            <div className="Character-wrapper">{}</div>
          </div>
        </div>
      );
    }
    if (this.state.loading) {
      return (
        <div className="App">
          <div className="Body-wrapper">
            {header}
            <RingLoader loading={this.state.loading} color="azure"/>
            <div className="Character-wrapper">{}</div>
          </div>
        </div>
      );
    } else {
      const {
        gender,
        name,
        history,
        profile,
        specialization,
        bonusTalent,
        appearance
      } = this.state.data;

      return (
        <div className="App">
          <div className="Body-wrapper">
            {header}
            <div className="Character-wrapper">
              <h2>Profile Summary</h2>
              <Options
                gender={gender}
                name={name}
                history={history}
                profile={profile}
                specialization={specialization}
                bonusTalent={bonusTalent}
              />
              <h2>Appearance</h2>
              <Accordion data={appearance} />
              <button className="refresh-button" onClick={this.fetchData}>
                Get New Shepard
              </button>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default App;
