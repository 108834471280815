import React from "react";
import "./Options.scss";

function Options(props) {
  const { gender, name, history, profile, specialization, bonusTalent } = props;
  return (
    <div className="Options">
      <div className="LeftSide">
        Custom
        <br />
        Name
        <br />
        Origin
        <br />
        Reputation
        <br />
        Class
        <br />
        Bonus Talent
      </div>
      <div className="RightSide">
        {gender}
        <br />
        {name} Shepard
        <br />
        {history}
        <br />
        {profile}
        <br />
        {specialization}
        <br />
        {bonusTalent}
      </div>
    </div>
  );
}

export default Options;
