import React from "react";
import "./Accordion.scss";
import AccordionItem from "./AccordionItem";

class Accordion extends React.Component {
  render() {
    const data = this.props.data;
    let accordionItems = [];
    Object.entries(data).forEach(([key, val]) => {
      accordionItems.push({ title: key, data: val });
    });
    return (
      <div {...{ className: "wrapper" }}>
        <ul {...{ className: "accordion-list" }}>
          {accordionItems.map((data, key) => {
            return (
              <li {...{ className: "accordion-list__item", key }}>
                <AccordionItem title={data.title} values={data.data} />
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

export default Accordion;
